<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto pt-8 pb-12 max-w-3xl">
      <div class="text-center">
        <radio-button
          :options="data.options"
          @input="handleUpdate"
          v-model="data.model" />

        <div
          v-if="data.meta.required && data.model === true"
          class="text-left pt-12">
          <input-textarea
            ref="meta"
            autofocus
            v-model="data.meta.model"
            :label="data.meta.title" />
        </div>
      </div>
    </div>

    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import validate from "validate.js"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  methods: {
    handleUpdate () {
      if (!this.data.meta.required || this.data.model === false) {
        this.$emit("continue")
      }
    }
  },

  computed: {
    metaModelErrors () {
      return validate.single(this.data.meta.model, {
        presence: true,
        length: {
          minimum: 2,
          message: "must be at least 2 characters"
        }
      })
    },

    modelErrors () {
      return validate.single(this.data.model, {
        presence: true,
        type: "boolean"
      })
    },

    isValid () {
      if (this.data.meta.required && this.data.model === true) {
        return (typeof this.modelErrors === "undefined") && (typeof this.metaModelErrors === "undefined")
      }

      return (typeof this.modelErrors === "undefined")
    }
  },

  watch: {
    "data.model" () {
      this.$nextTick(() => {
        if (this.data.meta.required && this.data.model === true) {
          this.$refs.meta.$el.scrollIntoView({behavior: "smooth"})
        }
      })
    }
  }
}
</script>
